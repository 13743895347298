import React from "react";

const IconDev=()=>{
    return(
        "ᴅᴇᴠ"
    )
    
}

export default IconDev
